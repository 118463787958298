import { default as $ } from 'jquery';

$(window).scroll(function() {
  var pageTop = $(document).scrollTop();
  var windowBottom = pageTop + $(window).height();

  $(".addAnimation").each(function() {
    var objectPosition = $(this).position().top + $(this).offset().top + $(this).outerHeight(true);
    var objectHeight = $(this).height();
    var objectBottom = objectPosition - objectHeight;
    
    /* If the element is completely within bounds of the window, fade it in */
    if (objectBottom < windowBottom) {
      jQuery(this).addClass('m-fadeIn');
    }
  });

  $(".cardAnimation").each(function() {
    var objectPosition = $(this).position().top + $(this).offset().top + $(this).outerHeight(true);
    var objectHeight = $(this).height();
    var objectBottom = objectPosition - objectHeight;
    
    if (objectBottom < windowBottom) {
      jQuery(this).find('.-card').addClass('cardFade');
    }
  });
}).scroll(); //invoke scroll-handler on page-load
