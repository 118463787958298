import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-products-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-arrows slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-green"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-arrows slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-green"></i></button>',
        appendArrows: $('.m-products-arrows'),
        swipeToSlide: true,
        slidesToShow: 3.7,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});