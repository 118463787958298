import { default as $ } from 'jquery';

$(document).ready(function () {
    $(window).on('resize', function(){
        if ($(window).width() > 768) {
            $('#mobileDropdown').hide();
        };
    });
    
    $('#mobileButton').on('click', function(){
        $('.header-hamburger-bars').toggleClass('-active');
        $('#mobileDropdown').slideToggle();
    });
    
    $('.menu-item-has-children').on('click', function(){
        $('.menu-item-has-children').removeClass('-show');
        $(this).toggleClass('-show');
    });
    
    $(".menu-item-has-children").on({
        mouseenter: function () {
            $('.menu-item-has-children').removeClass('-show');
        }
    });
});
