import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-timeline-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-arrows slick-prev me-2 me-sm-5"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-white"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-arrows slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-white"></i></button>',
        appendArrows: $('.m-timeline-arrows'),
        swipeToSlide: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});