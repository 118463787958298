import { default as $ } from 'jquery';

$(window).on("load",function() {
   $(".m-accordion-content").slideUp();
   $(".m-accordion-chevron").removeClass("-upsideDown");
});

$(".m-accordion-heading").on("click", function(){
   if ($(this).closest(".m-accordion-section").hasClass("-noShow")) {
      $(".m-accordion-content").slideUp();
      $(".m-accordion-chevron").removeClass("-upsideDown");
      $(".m-accordion-section").addClass("-noShow");
      $(this).closest(".m-accordion-section").removeClass("-noShow");
      $(this).closest(".m-accordion-section").find(".m-accordion-content").slideToggle();
      $(this).closest(".m-accordion-section").find(".m-accordion-chevron").toggleClass("-upsideDown");
   } else {
      $(this).closest(".m-accordion-section").addClass("-noShow");
      $(".m-accordion-content").slideUp();
      $(".m-accordion-chevron").removeClass("-upsideDown");
   };
});