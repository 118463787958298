import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).ready(function() {
    var $slider = $('.m-banner-gallery.-isCarousel');

    if ($slider.length) {
        var currentSlide;
        var slidesCount;
        var sliderCounter = document.createElement('span');
        sliderCounter.classList.add('m-banner-counter');
        
        var updateSliderCounter = function(slick, currentIndex) {
            currentSlide = slick.slickCurrentSlide() + 1;
            slidesCount = slick.slideCount;
            $(sliderCounter).text(currentSlide + '/' +slidesCount)
        };

        $slider.on('init', function(event, slick) {
            $slider.append(sliderCounter);
            updateSliderCounter(slick);
        });

        $slider.on('afterChange', function(event, slick, currentSlide) {
            updateSliderCounter(slick, currentSlide);
        });

        $slider.slick({
            dots: false,
            arrows: false,
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            useAutoplayToggleButton: false,
            rows: 0,
        });
    }
});