import { default as $ } from 'jquery';

$('.m-tabs-tab').hide();
$('.m-tabs-tab:first').show();
$('.m-tabs-name:first').addClass('-active');

$('.m-icons-tab').hide();
$('.m-icons-tab:first').show();
$('.m-icons-button:first').addClass('-active');

$(window).on("load",function() {
    
    $(".m-tabs-button").on("click", function(){
        var button = $(this).data('tab');
        var tabid = '#' + button + 'Content';
        $('.m-tabs-tab').hide();
        $(tabid).slideDown();
    
        $('.m-tabs-name').removeClass('-active');
        $(this).closest('.m-tabs-links').find('.m-tabs-name').addClass('-active');
    });

    $(".m-icons-button").on("click", function(){
        var button = $(this).data('tab');
        var tabid = '#' + button + 'Content';
        $('.m-icons-tab').hide();
        $(tabid).slideDown();
    
        $('.m-icons-button').removeClass('-active');
        $(this).addClass('-active');
    });
});
